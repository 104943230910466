import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {UserLayout} from '../../_metronic/layout/UserLayout'
import {HomePage} from '../user/pages/home/HomePage'
import PrivacyPolicy from '../user/modules/privacy-policy/PrivacyPolicy'
import {UnityBuild} from '../user/pages/home/UnityBuild'
import {ChatBotPage} from '../user/pages/home/ChatBotPage'

const UserRoutes = () => {
  const AboutUs = lazy(() => import('../user/modules/about/AboutPage'))
  const ContactUs = lazy(() => import('../user/modules/contact/ContactPage'))
  const ProfilePage = lazy(() => import('../user/modules/profile/ProfilePage'))
  const TOCPage = lazy(() => import('../user/modules/TOC/TOCPage'))

  // fetching personalized-form from local storage
  const personalizationFormObj = localStorage.getItem('personalizationFormObj')

  return (
    <Routes>
      <Route element={<UserLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route index element={<Navigate to={!personalizationFormObj ? '/home' : '/chat-bot'} />} />

        {/* Pages */}
        {/* <Route path='home' element={<PersonalizationForm />} /> */}

        {/* Lazy Modules */}
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='chat-bot'
          element={
            <SuspensedView>
              <ChatBotPage />
            </SuspensedView>
          }
        />

        <Route
          path='about'
          element={
            <SuspensedView>
              <AboutUs />
            </SuspensedView>
          }
        />

        <Route
          path='contact'
          element={
            <SuspensedView>
              <ContactUs />
            </SuspensedView>
          }
        />

        <Route
          path='policy'
          element={
            <SuspensedView>
              <PrivacyPolicy />
            </SuspensedView>
          }
        />

        <Route
          path='terms'
          element={
            <SuspensedView>
              <TOCPage />
            </SuspensedView>
          }
        />
        <Route
          path='unity-testing'
          element={
            <SuspensedView>
              <UnityBuild />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
        <Route path='*' element={<Navigate to={!personalizationFormObj ? '/home' : '/chat-bot'} />} />
      </Route>
      <Route path='home' element={<HomePage />} />
      {/* <Route
        path='personal'
        element={
          <SuspensedView>
            <PersonalizationForm />
          </SuspensedView>
        }
      /> */}
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {UserRoutes}
