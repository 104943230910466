import {memo} from 'react'
import {useChatBotState} from './ChatbotUtils'
import CustomSlider from './CustomSlider'
import CustomOverlayTooltip from '../../components/CustomOverlayTooltip'
import InvestmentCategorySelector from './InvestmentCategorySelector'

const SidePanelInputs: React.FC = () => {
  // destructuring compare performance utils state
  const {formik, riskTypes} = useChatBotState()

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        // className='personalization-form-container mt-20 w-75'
        id='side-panel-form'
      >
        <p className='fw-bold'>Set you preferences</p>
        <div className='d-flex align-items-baseline'>
          <label htmlFor='principal'>Investment Equities:</label>
          <CustomOverlayTooltip content='Preffered Assets' className='tooltip-logo' />
        </div>
        <div className='d-flex mb-4 mt-1'>
          {
            <InvestmentCategorySelector
              onAssetSelected={(value) => formik.setFieldValue('preffered_assets', value)}
            />
          }
        </div>
        {/* starting amount */}
        <div className='mb-4'>
          <div className='d-flex align-items-baseline'>
            <label htmlFor='principal'>Principal: ${formik.values.starting_amount}</label>
            <CustomOverlayTooltip content='Starting Amount' className='tooltip-logo' />
          </div>
          <CustomSlider
            value={formik.values.starting_amount}
            maxValue={100000}
            onRangeChange={(value: any) =>
              formik.setFieldValue('starting_amount', parseInt(value!))
            }
          />
        </div>
        {/* contributions */}
        <div className='mb-4'>
          <div className='d-flex align-items-baseline'>
            <label htmlFor='contributions'>Contributions: ${formik.values.contributions}</label>
            <CustomOverlayTooltip content='Contributions' className='tooltip-logo' />
          </div>
          <CustomSlider
            value={formik.values.contributions}
            maxValue={10000}
            isContributions={true}
            onRangeChange={(value: any) => formik.setFieldValue('contributions', parseInt(value!))}
          />
        </div>
        {/* years to grow */}
        <div className='mb-4'>
          <div className='d-flex align-items-baseline'>
            <label htmlFor='years-to-grow'>Time: {formik.values.years_to_grow}</label>
            <CustomOverlayTooltip content='Years to grow' className='tooltip-logo' />
          </div>
          <CustomSlider
            value={formik.values.years_to_grow}
            minValue={1}
            maxValue={20}
            isMoney={false}
            onRangeChange={(value: any) => formik.setFieldValue('years_to_grow', parseInt(value!))}
          />
        </div>
        {/* risk tolerance */}
        <div className='mb-3'>
          <div className='d-flex align-items-baseline'>
            <label htmlFor='risk-tolerance'>Risk Tolerance: {formik.values.risk_tolerance}</label>
            <CustomOverlayTooltip content='Risk tolerance' className='tooltip-logo' />
          </div>
          <CustomSlider
            value={riskTypes.indexOf(formik.values.risk_tolerance)}
            minValue={0}
            maxValue={2}
            isMoney={false}
            customSteps={1}
            onRangeChange={(value: any) =>
              formik.setFieldValue('risk_tolerance', riskTypes[parseInt(value!)])
            }
          />
        </div>
      </form>
    </>
  )
}

export default memo(SidePanelInputs)
