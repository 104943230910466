import React from 'react'
import {TooltipProps} from 'recharts'
import {toUSD} from '../charts.helper'

const FundComparisonTooltip: React.FC<TooltipProps<number, string>> = ({active, payload}) => {
  if (!active || !payload || payload.length === 0 || payload[0]?.payload?.label === 'Custom') {
    return null
  }

  // chart data
  const data = payload[0].payload

  const LossLabel = ({value}: {value: number}) => {
    return (
      <p className='paragraph-x-small mb-0 label-loss'>
        Loss: <span className='label-bold'>{toUSD(Math.abs(value))}</span>
      </p>
    )
  }

  const GainLabel = ({value}: {value: number}) => {
    return (
      <p className='paragraph-x-small mb-0 label-gain'>
        Gain: <span className='label-bold'>{toUSD(value)}</span>
      </p>
    )
  }

  return (
    <div className='custom-tooltip'>
      <p className='paragraph-small label-main'>{data.investment_name}</p>

      {/* Gains */}
      {!data.is_loss ? <GainLabel value={data?.gains} /> : <LossLabel value={data?.gains} />}

      {/* fee */}
      {data?.fee && data?.fee !== 0 && (
        <p className='paragraph-x-small mb-0 label-initial-deposit'>
          Fee: <span className='label-bold'>{toUSD(data?.fee)}</span>
        </p>
      )}

      {/* total cost */}
      {data?.totalCost !== 0 && (
        <p className='paragraph-x-small mb-0 gray-text'>
          Total cost: <span className='label-bold'>{toUSD(data?.totalCost)}</span>
        </p>
      )}

      {/* total value */}
      {data?.totalValue !== 0 && (
        <p className='paragraph-x-small mb-0 gray-text'>
          Total value: <span className='label-bold'>{toUSD(data?.totalValue)}</span>
        </p>
      )}

      {/* cost ratio */}
      {data?.costRatio !== 0 && (
        <p className='paragraph-x-small mb-0 gray-text'>
          Cost ratio: <span className='label-bold'>{data?.costRatio}%</span>
        </p>
      )}

      {/* Starting amount */}
      {data?.starting_amount !== 0 && (
        <p className='paragraph-x-small mb-0 label-initial-deposit'>
          Starting amount: <span className='label-bold'>{toUSD(data?.starting_amount)}</span>
        </p>
      )}
    </div>
  )
}

export default FundComparisonTooltip
