import React, {createContext, useState, useContext, Dispatch, SetStateAction, useMemo} from 'react'
import {WithChildren} from '../../../_metronic/helpers'
import {ISidePanel} from '../modules/chatbot/core/_model'

// fetching personalized-form from local storage
const personalizationFormObj = localStorage.getItem('personalizationFormObj')
const parsedPersonalizationFormObj = personalizationFormObj
  ? JSON.parse(personalizationFormObj)
  : {}

type ChatbotContextProps = {
  sidePanelPreferencesObj: ISidePanel
  setSidePanelPreferencesObj: Dispatch<SetStateAction<ISidePanel>>
  showSlider: boolean
  setShowSlider: Dispatch<SetStateAction<boolean>>
}

export const setYearsToGrow = (key: string) => {
  switch (key) {
    case '1 year < 1 year':
      return 1
    case '1-3 years > 1-3':
      return 3
    case '3-5 years > 3-5':
      return 5
    case '> 5 years':
      return 10
    default:
      return 0
  }
}

// default values
const initChatBotPropsState: ChatbotContextProps = {
  sidePanelPreferencesObj: personalizationFormObj
    ? {
        starting_amount: +parsedPersonalizationFormObj?.initial_investment_amount,
        contributions: +parsedPersonalizationFormObj?.contributions,
        years_to_grow: setYearsToGrow(parsedPersonalizationFormObj?.time_horizon),
        risk_tolerance: parsedPersonalizationFormObj?.risk_tolerance,
        preffered_assets: [],
      }
    : {
        starting_amount: 0,
        contributions: 0,
        years_to_grow: 0,
        risk_tolerance: '',
        preffered_assets: [],
      },
  setSidePanelPreferencesObj: () => {},
  showSlider: false,
  setShowSlider: () => {},
}

const ChatbotContext = createContext<ChatbotContextProps>(initChatBotPropsState)

const ChatbotProvider: React.FC<WithChildren> = React.memo(({children}) => {
  const [sidePanelPreferencesObj, setSidePanelPreferencesObj] = useState(
    initChatBotPropsState.sidePanelPreferencesObj
  )
  const [showSlider, setShowSlider] = useState(true)

  const contextValue = useMemo(
    () => ({
      sidePanelPreferencesObj,
      setSidePanelPreferencesObj,
      showSlider,
      setShowSlider,
    }),
    [sidePanelPreferencesObj, showSlider]
  )

  return <ChatbotContext.Provider value={contextValue}>{children}</ChatbotContext.Provider>
})

const useChatbotContext = () => {
  const context = useContext(ChatbotContext)
  if (!context) {
    throw new Error('useJoinWaitlistContext must be used within a JoinWaitListProvider')
  }
  return context
}

export {ChatbotProvider, useChatbotContext}
