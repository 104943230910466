import Modal from 'react-bootstrap/Modal'
import {Button} from 'react-bootstrap'
import {FormEvent, useState} from 'react'
import {addToWaitlist} from './core/_request'
import {toast} from 'react-toastify'
import useFocus from '../hooks/useFocus'
import {useJoinWaitlistContext} from '../user/context/JoinWaitlistContext'
import FeedbackSection from '../user/modules/components/feedbackSection'

interface FeedBackModalProps {
  show: boolean
  onHide: () => void
}

const FeedBackModal: React.FC<FeedBackModalProps> = (props) => {
  const {setShowModal} = useJoinWaitlistContext()
  // setting toastify success/error
  const notifyError = (msg: string) => toast.error(msg)
  const notifySuccess = (msg: string) => toast.success(msg)
  const {inputRef, focusInput} = useFocus()
  const [waitListEmail, setWaitListEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // handle submit
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // add user to waitlist
    setIsLoading(true)
    try {
      // const res = await addToWaitlist(waitListEmail)
      setIsLoading(false)
      setShowModal(false)
      // notifySuccess(res?.data?.message + '. Thank You!')
      setWaitListEmail('')
    } catch (error: any) {
      if (error?.response?.data) {
        notifyError(error?.response?.data?.message)
      } else {
        notifyError('Error occurred during the request.')
      }
      setIsLoading(false)
    }
  }

  // waitlist UI toggler
  const handleFeedBackUI = () => {
    return (
      <>
        <p className='ps-1 m-0 fields-label'>Did Chloee get you to your goal ?</p>
        <FeedbackSection sectionName='Feed Back On Goal Complition.' />
        {/* <div className='d-flex justify-content-end align-items-end'>
          <Button type='submit' className='border-0' disabled={isLoading}>
            {!isLoading && <span className='indicator-label'>SUBMIT</span>}
            {isLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </Button>
        </div> */}
      </>
    )
  }
  return (
    <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter' className='heading sub-heading-x-small'>
          Feedback
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{handleFeedBackUI()}</Modal.Body>
    </Modal>
  )
}

export default FeedBackModal
