import React from 'react'
interface CustomRangeSliderProps {
  value: number
  minValue?: number
  maxValue: number
  isMoney?: boolean
  isContributions?: boolean
  customSteps?: number
  onRangeChange: (value: number) => void
}

const CustomSlider: React.FC<CustomRangeSliderProps> = ({
  value,
  minValue = 0,
  maxValue,
  isMoney = true,
  isContributions = false,
  customSteps,
  onRangeChange,
}) => {
  // calculate the step dynamically based on the range
  const step = !isMoney
    ? (maxValue - minValue) / 20
    : isContributions
    ? (maxValue - minValue) / 200
    : (maxValue - minValue) / 100 // you can adjust the divisor as needed

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value)
    onRangeChange(newValue)
  }

  return (
    <>
      <input
        type='range'
        className='form-range slider'
        min={minValue}
        max={maxValue}
        step={customSteps ? customSteps : step}
        onChange={handleInputChange}
        value={value}
      />
    </>
  )
}

export default CustomSlider

// import React from 'react'
// import {Slider} from 'rsuite'
// import 'rsuite/dist/rsuite-no-reset.min.css'
// import {KTSVG} from '../../../../../_metronic/helpers'

// interface CustomRangeSliderProps {
//   value: number
//   minValue?: number
//   maxValue: number
//   isMoney?: boolean
//   isPastYears?: boolean
//   customSteps?: number
//   onRangeChange: (value: number) => void
// }

// export const Thumb = () => {
//   return (
//     <svg width='24' height='21' viewBox='0 0 24 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
//       <path
//         d='M21.24 18.1L12 2.89998L2.75999 18.1H21.24Z'
//         fill='#367045'
//         stroke='#367045'
//         stroke-width='3.91667'
//         stroke-linecap='round'
//         stroke-linejoin='round'
//       />
//       <line x1='15' y1='17' x2='15' y2='8' stroke='white' stroke-width='2' />
//       <line x1='9' y1='17' x2='9' y2='8' stroke='white' stroke-width='2' />
//     </svg>
//   )
// }
// const CustomSlider: React.FC<CustomRangeSliderProps> = ({
//   value,
//   minValue = 0,
//   maxValue,
//   isMoney = true,
//   isPastYears = false,
//   customSteps,
//   onRangeChange,
// }) => {
//   // Calculate the step dynamically based on the range
//   const step =
//     !isMoney && !isPastYears
//       ? (maxValue - minValue) / 20
//       : isPastYears
//       ? (maxValue - minValue) / 24
//       : (maxValue - minValue) / 10 // You can adjust the divisor as needed

//   const incrementValue = () => {
//     if (value + step <= maxValue) {
//       let newValue

//       if (isMoney) {
//         // For money values, round up to the nearest multiple of 10
//         // newValue = Math.floor((value + step) / step) * step
//         newValue = value + step
//       } else {
//         newValue = value
//         newValue += 1
//       }
//       onRangeChange(newValue)
//     }
//   }

//   const decrementValue = () => {
//     if (value > maxValue) {
//       return
//     } else {
//       if (value - step >= minValue) {
//         let newValue

//         if (isMoney) {
//           // For money values, round down to the nearest multiple of 10
//           newValue = value - step
//         } else {
//           // For non-money values, find the nearest multiple of 10
//           newValue = value
//           newValue -= 1
//         }
//         onRangeChange(newValue > minValue ? newValue : minValue)
//       } else if (!isMoney && value - step < minValue) {
//         onRangeChange(minValue)
//       }
//     }
//   }

//   const handleInputChange = (newValue: number) => {
//     onRangeChange(newValue)
//   }

//   return (
//     <div className='row justify-content-center mb-2'>
//       <div className='row justify-content-between d-flex align-items-center'>
//         <div className='col-1 fw-600 cursor-pointer p-0 m-0' onClick={decrementValue}>
//           <KTSVG path='/media/icons/duotune/general/minus.svg' />
//         </div>

//         <div className='col-10 p-0 px-3 m-0'>
//           <Slider
//             min={minValue}
//             max={maxValue}
//             value={value}
//             step={customSteps ? customSteps : step}
//             tooltip={false}
//             className='custom-slider'
//             onChange={handleInputChange}
//             progress
//             handleStyle={{
//               borderRadius: 2,
//               color: '#fff',
//               fontSize: 12,
//               width: 32,
//               height: 22,
//             }}
//             handleTitle={<Thumb />}
//             graduated // Display step indicators
//           />
//         </div>

//         <div className='col-1 fw-600 cursor-pointer p-0 m-0' onClick={incrementValue}>
//           <KTSVG path='/media/icons/duotune/general/plus.svg' />
//         </div>
//       </div>
//     </div>
//   )
// }

// export default CustomSlider
