import React, {useState} from 'react'
import {useChatBotState} from './ChatbotUtils'
import {useChatbotContext} from '../../../context/ChatbotContext'

interface IDefaultAssetsProps {
  onAssetSelected: (values: string[]) => void // Adjust to emit an array of values
}

const InvestmentCategorySelector: React.FC<IDefaultAssetsProps> = ({onAssetSelected}) => {
  const {defaultPrefferedAssets} = useChatBotState()
  const {sidePanelPreferencesObj} = useChatbotContext()

  // initialize activeAssets as an empty array to support multiple selections
  const [activeAssets, setActiveAssets] = useState<string[]>(
    sidePanelPreferencesObj?.preffered_assets
  )

  const handlePrefferedAssetsClick = (value: string) => {
    // Determine if the value is already selected
    const index = activeAssets.indexOf(value)
    let newActiveAssets = [...activeAssets]

    if (index === -1) {
      // If not selected, add it to the array
      newActiveAssets.push(value)
    } else {
      // If already selected, remove it from the array
      newActiveAssets.splice(index, 1)
    }

    setActiveAssets(newActiveAssets)
    onAssetSelected(newActiveAssets) // Emit the updated array back to the parent component
  }

  const handleRemoveClick = (
    value: string,
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.stopPropagation() // Prevent click event from bubbling to the button
    const newActiveAssets = activeAssets.filter((asset) => asset !== value)
    setActiveAssets(newActiveAssets)
    onAssetSelected(newActiveAssets)
  }

  return (
    <div className='active-asset-selector d-flex overflow-x-auto'>
      {defaultPrefferedAssets.map((asset) => (
        <button
          key={asset}
          onClick={() => handlePrefferedAssetsClick(asset)}
          className={activeAssets.includes(asset) ? 'active' : ''}
        >
          {asset}
          {activeAssets.includes(asset) && (
            <span
              onClick={(event) => handleRemoveClick(asset, event)}
              style={{marginLeft: '10px', cursor: 'pointer'}}
            >
              X
            </span>
          )}
        </button>
      ))}
    </div>
  )
}

export default InvestmentCategorySelector
