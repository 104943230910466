/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {AdminRoutes} from './AdminRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {UserRoles} from '../config/UserRoles'
import {UserRoutes} from './UserRoutes'
import WaitListPage from '../user/pages/htmlPage/WaitListPage'
import CanvaPreview from '../user/pages/htmlPage/CanvaPreview'
import {ResetPassword} from '../modules/auth/components/ResetPassword'
import {ChangePassword} from '../user/pages/auth/ChangePassword'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()

  // fetching personalized-form from local storage
  const personalizationFormObj = localStorage.getItem('personalizationFormObj')
  const waitlistStep = Number(localStorage.getItem('waitlist-step'))

  const RootRedirect = () => {
    useEffect(() => {
      window.location.href = 'https://joinwaitlist.chloee.xyz'
    }, [])

    return null
  }

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              {/* if loggedIn user is admin then navigate to admin routes */}
              {currentUser.user_role === UserRoles.admin ? (
                <>
                  <Route path='/*' element={<AdminRoutes />} />
                  <Route index element={<Navigate to='/dashboard' />} />
                </>
              ) : null}
              {/* if loggedIn user is admin then navigate to admin routes */}
              {currentUser.user_role === UserRoles.user ? (
                <>
                  {currentUser.is_first_login ? (
                    <>
                      <Route path='/change-password' element={<ChangePassword />} />
                      <Route path='*' element={<Navigate to='/change-password' />} />
                    </>
                  ) : (
                    <>
                      <Route
                        index
                        element={<Navigate to={!personalizationFormObj ? '/home' : '/chat-bot'} />}
                      />
                      <Route path='/*' element={<UserRoutes />} />
                    </>
                  )}
                </>
              ) : null}
            </>
          ) : (
            <>
              <Route path='/join-waitlist' element={<WaitListPage />} />
              <Route path='/preview/:slug' element={<CanvaPreview />} />

              <Route path='auth/*' element={<AuthPage />} />
              <Route
                path='/'
                element={
                  !waitlistStep ? (
                    <RootRedirect />
                  ) : (
                    <Navigate to={waitlistStep === 3 ? '/auth' : '/join-waitlist'} />
                  )
                }
              />
              <Route
                path='*'
                element={<Navigate to={waitlistStep === 3 ? '/auth' : '/join-waitlist'} />}
              />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
