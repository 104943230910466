import {BarChart, Bar, XAxis, ResponsiveContainer, Tooltip, LabelList, Cell} from 'recharts'
import useScreenWidth from '../../../hooks/useScreenWidth'
import {stackBarColors} from '../charts.helper'
import StackBarChartTooltip from '../ProjectionChartTooltip'
import CustomizedBarTopLabel from '../CustomizedBarTopLabel'
import CustomizedAxisTick from '../CustomizedAxisTick'

const ProjectionsChart: React.FC<any> = (props) => {
  // destructuring props
  const {data} = props
  // calculating screen width hook
  const screenWidth = useScreenWidth()
  // calculating chart height
  let chartPadding
  let _chartHeight
  // calculating chart padding
  chartPadding =
    screenWidth < 546
      ? {top: 50, bottom: 15, right: 30, left: 30}
      : {top: 80, bottom: 30, right: 40, left: 40}

  _chartHeight = screenWidth > 767 ? 410 : screenWidth > 567 ? 250 : 200

return (
  <ResponsiveContainer width='90%' height={_chartHeight} id='chart-container' className='m-auto'>
    <BarChart
      data={data}
      margin={chartPadding}
      style={{backgroundColor: '#DDF2F4'}}
      barSize={screenWidth < 546 ? 50 : 100}
      barCategoryGap={10} // adjust the gap between bars in a category (group)
      barGap={5} // adjust the gap between different categories (groups)
    >
      <XAxis
        dataKey='rate_of_return_value'
        axisLine={false}
        tickLine={false}
        interval={0}
        width={100}
        minTickGap={5}
        tick={<CustomizedAxisTick {...props} data={data} screenWidth={screenWidth} />}
      />
      <XAxis
        dataKey='investment_name'
        axisLine={false}
        tickLine={false}
        interval={0}
        width={100}
        minTickGap={5}
        tick={<CustomizedAxisTick {...props} data={data} screenWidth={screenWidth} />}
      />
      {/* tooltip */}
      <Tooltip content={<StackBarChartTooltip />} />
      {/* bars */}

      <Bar dataKey='starting_amount' stackId='assets' fill={stackBarColors.startingAmount} />
      <Bar dataKey='contributions' stackId='assets' fill={stackBarColors.contributions} />
      <Bar dataKey='gains' stackId='assets' fill={stackBarColors.contributions}>
        {data.map((entry: any, index: number) => (
          <Cell
            key={`cell-${index}`}
            fill={`${entry?.is_loss ? stackBarColors.loss : stackBarColors.gain}`}
          />
        ))}
        <LabelList
          dataKey='future_value'
          content={<CustomizedBarTopLabel {...props} screenWidth={screenWidth} />}
          position='top'
        />
      </Bar>
      <Bar dataKey='loss' stackId='assets' fill={stackBarColors.loss} />
    </BarChart>
  </ResponsiveContainer>
)
}
export default ProjectionsChart
