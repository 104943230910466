// CustomizedAxisTick.tsx
import React from 'react'
import {Text} from 'recharts'
import {stackBarColors} from './charts.helper'

interface ICustomizedAxisTick {
  x: number
  y: number
  payload: any
  screenWidth: number
  data: any[] // Type this according to your data structure
  index: number
  isFundComparison?: boolean
  isCreativeEdge?: boolean
}

const CustomizedAxisTick: React.FC<ICustomizedAxisTick> = ({
  x,
  y,
  payload,
  screenWidth,
  data,
  index,
  isFundComparison = false,
  isCreativeEdge = false,
}) => {
  const labelPadding = screenWidth < 776 ? 8 : 0
  const labelTextAngle = screenWidth < 776 ? -30 : 0

  return (
    <>
      {/* funds comparison chart customized axis tick */}
      {isFundComparison ? (
        <>
          <Text
            x={screenWidth > 767 ? x - 52 : x - 40}
            y={y + labelPadding}
            width={90}
            textAnchor='middle'
            verticalAnchor='start'
            angle={labelTextAngle}
            className={`${
              isCreativeEdge ? 'custom-tick-label-funds-transfer' : 'custom-tick-label'
            }`}
            fill={stackBarColors.black}
          >
            What You Pay
          </Text>
          <Text
            x={screenWidth > 767 ? x + 52 : x + 15}
            y={y + labelPadding}
            width={90}
            textAnchor='middle'
            verticalAnchor='start'
            angle={labelTextAngle}
            className={`${
              isCreativeEdge ? 'custom-tick-label-funds-transfer' : 'custom-tick-label'
            }`}
            fill={stackBarColors.black}
          >
            What You Get
          </Text>
        </>
      ) : (

        data[index].manipulation_type?(
          <Text
          x={x}
          y={y + labelPadding}
          width={90}
          textAnchor='middle'
          verticalAnchor='start'
          angle={labelTextAngle}
          className='custom-tick-label'
          fill={stackBarColors.startingAmount}
        >
          {data[index]?.manipulation_type}
        </Text>
        ):( <>
          <Text
            x={x}
            y={y + labelPadding}
            width={90}
            textAnchor='middle'
            verticalAnchor='start'
            angle={labelTextAngle}
            className='custom-tick-label'
            fill={stackBarColors.black}
          >
            {data[index]?.investment_name}
          </Text>
          <Text
            x={x}
            y={y + 25}
            width={90}
            textAnchor='middle'
            verticalAnchor='start'
            angle={labelTextAngle}
            className='custom-label custom-label-bold'
            fill={stackBarColors.startingAmount}
          >
            {payload?.value + '%'}
          </Text>
          {data[index].oldValue ? (
            <Text
              x={x}
              y={y + 40}
              width={90}
              textAnchor='middle'
              verticalAnchor='start'
              angle={labelTextAngle}
              className='custom-label custom-label-bold'
              fill={stackBarColors.startingAmount}
            >
              (Old Value)
            </Text>
          ) : null}
        </>)
        // stack bar chart customized axis tick
       
      )}
    </>
  )
}

export default CustomizedAxisTick
