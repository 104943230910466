import {
  BarChart,
  Bar,
  XAxis,
  ResponsiveContainer,
  Tooltip,
  LabelList,
  ReferenceLine,
} from 'recharts'
import useScreenWidth from '../../../hooks/useScreenWidth'
import {stackBarColors} from '../charts.helper'
import CustomizedBarTopLabel from '../CustomizedBarTopLabel'
import CustomizedAxisTick from '../CustomizedAxisTick'
import FundComparisonTooltip from './FundComparisonTooltip'
import {useEffect, useState} from 'react'

const FundsComparisonChart: React.FC<any> = (props) => {
  // destructuring props
  const originalData = props.data

  let modifiedData = JSON.parse(JSON.stringify(originalData))
  if (modifiedData.length > 1) {
    // Perform your data manipulation here
    modifiedData[0].fee2 = modifiedData[1].fee
    modifiedData[1].future_value2 = modifiedData[0].future_value

    delete modifiedData[0].future_value
    delete modifiedData[1].fee
  }

  // calculating screen width hook
  const screenWidth = useScreenWidth()

  const [toggleChart, setToggleChart] = useState(0)
  const [showAnimation, setShowAnimation] = useState(true)
  const [isCreativeEdge, setIsCreativeEdge] = useState(false)
  const [renderedData, setRenderedData] = useState<any[]>([])

  // creating animations
  useEffect(() => {
    setRenderedData([])
    if (showAnimation) {
      if (toggleChart === 2) {
        const renderBarsWithDelay = async () => {
          for (let i = 0; i < [...modifiedData].length; i++) {
            await new Promise((resolve) => setTimeout(resolve, 300)) // 0.5 second delay
            setRenderedData((prevData) => [...prevData, [...modifiedData][i]])
          }
        }
        renderBarsWithDelay()
      } else {
        const renderBarsWithDelay = async () => {
          for (let i = 0; i < [...originalData].length; i++) {
            await new Promise((resolve) => setTimeout(resolve, 300)) // 0.5 second delay
            setRenderedData((prevData) => [...prevData, [...originalData][i]])
          }
        }
        renderBarsWithDelay()
      }
    }
    setShowAnimation(false)
  }, [originalData, showAnimation])

  // calculating chart height
  let chartPadding: any
  let _chartHeight: any
  // calculating chart padding
  chartPadding =
    screenWidth < 546
      ? {top: 50, bottom: 15, right: 30, left: 30}
      : {top: 80, bottom: 30, right: 40, left: 40}

  _chartHeight = screenWidth > 767 ? 410 : screenWidth > 567 ? 250 : 200

  const barStrokeColor = isCreativeEdge ? '#000' : '',
    barStrokeWidth = isCreativeEdge ? 1 : 0

  // handle chart toggle
  const handleToggleChart = (chartId: number) => {
    setToggleChart(chartId)
    setShowAnimation(true)
  }

  // handle chart UI
  const handleFundsComparisonChart = (toggleChart: number) => {
    switch (toggleChart) {
      case 1:
        return (
          // stack chart
          <>
            <ResponsiveContainer
              width='90%'
              height={_chartHeight}
              id='chart-container'
              className='m-auto'
            >
              <BarChart
                data={renderedData}
                margin={chartPadding}
                style={{backgroundColor: `${isCreativeEdge ? 'white' : '#DDF2F4'}`}}
                barSize={screenWidth < 546 ? 50 : 100}
                barCategoryGap={10} // adjust the gap between bars in a category (group)
                barGap={5} // adjust the gap between different categories (groups)
              >
                <XAxis
                  dataKey='label'
                  axisLine={{stroke: 'black', strokeWidth: 0.5}}
                  tickLine={false}
                  interval={0}
                  minTickGap={5}
                />

                {/* tooltip */}
                <Tooltip content={<FundComparisonTooltip />} />

                {/* reference line */}
                <>
                  <ReferenceLine
                    y={originalData[0]?.fee}
                    stroke='transparent'
                    // strokeDasharray='3 3'
                    textAnchor='bottom'
                    label={{
                      dx: screenWidth > 767 ? 50 : 28,
                      dy: -10,
                      position: 'left',
                      value: 'What You Pay',
                      className: 'custom-tick-label',
                    }}
                  />
                  <ReferenceLine
                    y={originalData[0]?.future_value}
                    stroke='transparent'
                    textAnchor='bottom'
                    label={{
                      dx: screenWidth > 767 ? 50 : 28,
                      dy: 10,
                      position: 'left',
                      value: 'What You Get',
                      className: 'custom-tick-label',
                    }}
                  />
                </>

                {/* bars */}
                <Bar
                  dataKey='fee'
                  stackId='funds'
                  fill={
                    isCreativeEdge
                      ? stackBarColors.fundsComparisonStartingAmount
                      : stackBarColors.startingAmount
                  }
                  stroke={barStrokeColor}
                  strokeWidth={barStrokeWidth}
                >
                  <LabelList
                    dataKey='fee'
                    content={
                      <CustomizedBarTopLabel
                        {...props}
                        screenWidth={screenWidth}
                        isCreativeEdge={isCreativeEdge}
                      />
                    }
                    position='top'
                  />
                </Bar>
                <Bar
                  dataKey='future_value'
                  stackId='funds'
                  fill={isCreativeEdge ? stackBarColors.fundsComparisonGain : stackBarColors.gain}
                  stroke={barStrokeColor}
                  strokeWidth={barStrokeWidth}
                >
                  <LabelList
                    dataKey='future_value'
                    content={
                      <CustomizedBarTopLabel
                        {...props}
                        screenWidth={screenWidth}
                        isCreativeEdge={isCreativeEdge}
                      />
                    }
                    position='top'
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </>
        )
      case 2:
        return (
          // sort chart
          <div className='d-flex'>
            <div
              className='chart-container'
              style={{width: '90%', height: _chartHeight, overflow: 'hidden', margin: 'auto'}}
            >
              <ResponsiveContainer
                width='90%'
                height={_chartHeight}
                id='chart-container'
                className='m-auto'
              >
                <BarChart
                  data={[renderedData[0]]}
                  margin={chartPadding}
                  style={{backgroundColor: `${isCreativeEdge ? 'white' : '#DDF2F4'}`}}
                  barSize={screenWidth < 546 ? 50 : 100}
                  barCategoryGap={10} // adjust the gap between bars in a category (group)
                  barGap={5} // adjust the gap between different categories (groups)
                >
                  <XAxis
                    dataKey='label'
                    axisLine={{stroke: 'black', strokeWidth: 0.5}}
                    tickLine={false}
                    interval={0}
                    minTickGap={5}
                    tick={
                      <CustomizedAxisTick
                        {...props}
                        data={originalData}
                        screenWidth={screenWidth}
                        isFundComparison={true}
                        isCreativeEdge={isCreativeEdge}
                      />
                    }
                  />

                  {/* tooltip */}
                  <Tooltip content={<FundComparisonTooltip />} />
                  {/* bars */}
                  <Bar
                    dataKey='fee'
                    fill={
                      isCreativeEdge
                        ? stackBarColors.fundsComparisonStartingAmount
                        : stackBarColors.startingAmount
                    }
                    stroke={barStrokeColor}
                    strokeWidth={barStrokeWidth}
                  >
                    <LabelList
                      dataKey='fee'
                      content={
                        <CustomizedBarTopLabel
                          {...props}
                          screenWidth={screenWidth}
                          isCreativeEdge={isCreativeEdge}
                        />
                      }
                      position='top'
                    />
                  </Bar>
                  <Bar
                    dataKey='fee2'
                    fill={
                      isCreativeEdge
                        ? stackBarColors.fundsComparisonStartingAmount
                        : stackBarColors.startingAmount
                    }
                    stroke={barStrokeColor}
                    strokeWidth={barStrokeWidth}
                  >
                    <LabelList
                      dataKey='fee2'
                      content={
                        <CustomizedBarTopLabel
                          {...props}
                          screenWidth={screenWidth}
                          isCreativeEdge={isCreativeEdge}
                        />
                      }
                      position='top'
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div
              className='chart-container'
              style={{width: '90%', height: _chartHeight, overflow: 'hidden', margin: 'auto'}}
            >
              <ResponsiveContainer
                width='90%'
                height={_chartHeight}
                id='chart-container'
                className='m-auto'
              >
                <BarChart
                  data={[renderedData[1]]}
                  margin={chartPadding}
                  style={{backgroundColor: `${isCreativeEdge ? 'white' : '#DDF2F4'}`}}
                  barSize={screenWidth < 546 ? 50 : 100}
                  barCategoryGap={10} // adjust the gap between bars in a category (group)
                  barGap={5} // adjust the gap between different categories (groups)
                >
                  <XAxis
                    dataKey='label'
                    axisLine={{stroke: 'black', strokeWidth: 0.5}}
                    tickLine={false}
                    interval={0}
                    minTickGap={5}
                    tick={
                      <CustomizedAxisTick
                        {...props}
                        data={originalData}
                        screenWidth={screenWidth}
                        isFundComparison={true}
                        isCreativeEdge={isCreativeEdge}
                      />
                    }
                  />

                  {/* tooltip */}
                  <Tooltip content={<FundComparisonTooltip />} />
                  {/* bars */}
                  <Bar
                    dataKey='future_value'
                    fill={isCreativeEdge ? stackBarColors.fundsComparisonGain : stackBarColors.gain}
                    stroke={barStrokeColor}
                    strokeWidth={barStrokeWidth}
                  >
                    <LabelList
                      dataKey='future_value'
                      content={
                        <CustomizedBarTopLabel
                          {...props}
                          screenWidth={screenWidth}
                          isCreativeEdge={isCreativeEdge}
                        />
                      }
                      position='top'
                    />
                  </Bar>
                  <Bar
                    dataKey='future_value2'
                    fill={isCreativeEdge ? stackBarColors.fundsComparisonGain : stackBarColors.gain}
                    stroke={barStrokeColor}
                    strokeWidth={barStrokeWidth}
                  >
                    <LabelList
                      dataKey='future_value2'
                      content={
                        <CustomizedBarTopLabel
                          {...props}
                          screenWidth={screenWidth}
                          isCreativeEdge={isCreativeEdge}
                        />
                      }
                      position='top'
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        )
      default:
        return (
          // split chart
          <>
            <ResponsiveContainer
              width='90%'
              height={_chartHeight}
              id='chart-container'
              className='m-auto'
            >
              <BarChart
                data={renderedData}
                margin={chartPadding}
                style={{backgroundColor: `${isCreativeEdge ? 'white' : '#DDF2F4'}`}}
                barSize={screenWidth < 546 ? 50 : 100}
                barCategoryGap={10} // adjust the gap between bars in a category (group)
                barGap={5} // adjust the gap between different categories (groups)
              >
                <XAxis
                  dataKey='label'
                  axisLine={{stroke: 'black', strokeWidth: 0.5}}
                  tickLine={false}
                  interval={0}
                  minTickGap={5}
                  tick={
                    <CustomizedAxisTick
                      {...props}
                      data={originalData}
                      screenWidth={screenWidth}
                      isFundComparison={true}
                      isCreativeEdge={isCreativeEdge}
                    />
                  }
                />

                {/* tooltip */}
                <Tooltip content={<FundComparisonTooltip />} />
                {/* bars */}
                <Bar
                  dataKey='fee'
                  fill={
                    isCreativeEdge
                      ? stackBarColors.fundsComparisonStartingAmount
                      : stackBarColors.startingAmount
                  }
                  stroke={barStrokeColor}
                  strokeWidth={barStrokeWidth}
                >
                  <LabelList
                    dataKey='fee'
                    content={
                      <CustomizedBarTopLabel
                        {...props}
                        screenWidth={screenWidth}
                        isCreativeEdge={isCreativeEdge}
                      />
                    }
                    position='top'
                  />
                </Bar>
                <Bar
                  dataKey='future_value'
                  fill={isCreativeEdge ? stackBarColors.fundsComparisonGain : stackBarColors.gain}
                  stroke={barStrokeColor}
                  strokeWidth={barStrokeWidth}
                >
                  <LabelList
                    dataKey='future_value'
                    content={
                      <CustomizedBarTopLabel
                        {...props}
                        screenWidth={screenWidth}
                        isCreativeEdge={isCreativeEdge}
                      />
                    }
                    position='top'
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </>
        )
    }
  }

  return (
    <>
      {handleFundsComparisonChart(toggleChart)}
      <div className='d-flex justify-content-end gap-2 me-20 mb-5'>
        {/* split chart button */}
        <button className='chat-button text-white' onClick={() => handleToggleChart(0)}>
          Split
        </button>
        {/* sort chart button */}
        {originalData.length > 1 ? (
          <button className='chat-button text-white' onClick={() => handleToggleChart(2)}>
            Sort
          </button>
        ) : null}
        {/* stack chart button */}
        <button className='chat-button text-white' onClick={() => handleToggleChart(1)}>
          Stack
        </button>
      </div>
      <div className='form-check form-switch mt-3 d-flex justify-content-end align-items-center me-20'>
        <input
          className='form-check-input'
          type='checkbox'
          role='switch'
          id='flexSwitchCheckDefault'
          onClick={() => setIsCreativeEdge(!isCreativeEdge)}
        />
        <label
          className='sliding-toggle-label form-check-label ms-2'
          htmlFor='flexSwitchCheckDefault'
        >
          Creative Edge
        </label>
      </div>
    </>
  )
}
export default FundsComparisonChart
