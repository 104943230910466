import {useChatbotContext} from '../../../context/ChatbotContext'
import SidePanelInputs from './SidePanelInputs'

const SidePanel: React.FC = () => {
  const {showSlider, setShowSlider} = useChatbotContext()
  return (
    <>
      {showSlider ? (
        <div className='side-panel'>
          <div className='text-end'>
            <i className='fas fa-chevron-left' onClick={() => setShowSlider(!showSlider)} />
          </div>
          <SidePanelInputs />
        </div>
      ) : (
        <i
          className='fas fa-chevron-right pt-8 wobble-effect d-none d-lg-block'
          title='Set Preferenses'
          onClick={() => setShowSlider(!showSlider)}
        />
      )}
    </>
  )
}

export default SidePanel
