import React from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {CONSTANTS} from '../../../constants/AppConstants'

interface TooltipProps {
  content: string
  className?: string
}

const CustomOverlayTooltip: React.FC<TooltipProps> = ({content, className}) => {
  const imgClassName = `ms-sm-2 pb-5 ${className}`
  return (
    <OverlayTrigger
      placement='bottom'
      overlay={<Tooltip style={{whiteSpace: 'pre-line'}}>{content}</Tooltip>}
    >
      <span>
        {className ? (
          <img className={imgClassName} src={CONSTANTS.IMAGES.TOOLTIP_OVERLAY} alt='tooltip' />
        ) : (
          <i className='fa fa-info-circle imgClassName' style={{color: '#181c32'}} />
        )}
      </span>
    </OverlayTrigger>
  )
}
export default CustomOverlayTooltip
