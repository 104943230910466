import React from 'react'
import {Text} from 'recharts'
import {toAbbreviatedNumber, stackBarColors} from './charts.helper'

interface ICustomizedBarTopLabel {
  x: number
  y: number
  width: number
  value: any
  screenWidth: number
  isCreativeEdge?: boolean
}

const CustomizedBarTopLabel: React.FC<ICustomizedBarTopLabel> = ({
  x,
  y,
  width,
  value,
  screenWidth,
  isCreativeEdge = false,
}) => {
  const futureValue = '$' + toAbbreviatedNumber(parseFloat(value?.toFixed(2)))
  const labelFVAngle = screenWidth < 410 ? -30 : 0

  return (
    <Text
      x={x + width / 2}
      y={screenWidth > 767 ? y - 23 : y - 15}
      textAnchor='middle'
      // className='custom-label custom-label-bold'
      className={`${
        isCreativeEdge
          ? 'custom-label-funds-transfer custom-label-bold'
          : 'custom-label custom-label-bold'
      }`}
      fill={stackBarColors.startingAmount}
      angle={labelFVAngle}
    >
      {futureValue ? futureValue : '$X'}
    </Text>
  )
}

export default CustomizedBarTopLabel
