import MetaTags from '../../../Meta/MetaTags'
import PersonalizationForm from '../../modules/personalization-form/PersonalizationForm'

const HomePage = () => {
  return (
    <>
      {/* meta tags for SEO */}
      <MetaTags URL={window.location.href} />
      <PersonalizationForm />
    </>
  )
}

export {HomePage}
