import {memo, useState} from 'react'
import useScreenWidth from '../hooks/useScreenWidth'
import SBLOCTooltip from './components/SBLOCTooltip'
import {
  BarChart,
  Bar,
  XAxis,
  ResponsiveContainer,
  Tooltip,
  LabelList,
  Text,
  YAxis,
  LineChart,
  CartesianGrid,
  Legend,
  Line,
} from 'recharts'
import {toAbbreviatedNumber, projectionsColors, SBLOCChartProps} from './components/charts.helper'

const SBLOCChart: React.FC<SBLOCChartProps> = (props) => {
  const {data} = props
  const [isLineGraph, setIsLineGraph] = useState<boolean>(false)
  // screen width custom hook
  const screenWidth = useScreenWidth()
  // calculating chart height
  let chartPadding
  let _chartHeight
  // calculating chart padding
  chartPadding =
    screenWidth < 546
      ? {top: 50, bottom: 15, right: 30, left: -30}
      : {top: 80, bottom: 30, right: 40, left: -30}

  _chartHeight = screenWidth > 767 ? 410 : screenWidth > 567 ? 250 : 200
  const labelPadding = screenWidth < 776 ? 8 : 0
  const labelTextAngle = screenWidth < 776 ? -30 : 0

  // customized bar top label
  const CustomizedBarTopLabel = (props: any) => {
    const {x, y, index, width} = props
    const futureValue = (data[index]?.topLabelValue).toFixed(2)
    const gain = '$' + toAbbreviatedNumber(parseFloat(futureValue))

    return (
      <Text
        x={!isLineGraph ? x + width / 2 : x}
        y={!isLineGraph ? (screenWidth > 767 ? y - 23 : y - 15) : y - 10}
        textAnchor='middle'
        className='custom-label'
        fill='#9b258f'
        angle={data.length <= 10 ? 0 : 270}
      >
        {gain}
      </Text>
    )
  }

  // custom bottom labels of the chart
  const CustomizedAxisTick = (props: any) => {
    const {x, y, index} = props
    let date = new Date()
    return (
      <Text
        x={x}
        y={y + labelPadding}
        width={90}
        textAnchor='middle'
        verticalAnchor='start'
        angle={labelTextAngle}
        className='custom-tick-label'
      >
        {date.getFullYear() + index}
      </Text>
    )
  }

  const CustomizedDot = (props: any) => {
    // Customize the appearance of data points here
    const {cx, cy, payload} = props
    return (
      <text x={cx} y={cy - 10} fill='#8884d8' textAnchor='middle'>
        $
      </text>
    )
  }

  return (
    <>
      <div className=' pt-20 pt-8 pt-7 px-md-10 px-lg-20'>
        {/* <div className=' py-20 py-8'> */}
        <ResponsiveContainer width='100%' height={400} id='chart-container'>
          {!isLineGraph ? (
            <BarChart margin={chartPadding} data={data} style={{backgroundColor: 'white'}}>
              <XAxis
                dataKey='yearOrMonth'
                axisLine={false}
                tickLine={false}
                interval={0}
                width={100}
                minTickGap={5}
                tick={<CustomizedAxisTick />}
              />
              <YAxis
                yAxisId='left'
                allowDataOverflow={true}
                tick={false}
                axisLine={false}
                tickLine={false}
                domain={[
                  (dataMin: number) => 0 - Math.abs(dataMin),
                  (dataMax: number) => dataMax * 1.2, // Increase dataMax by 10%
                ]}
              />
              {/* tooltip */}
              <Tooltip contentStyle={{backgroundColor: 'white'}} content={<SBLOCTooltip />} />
              {/* bars */}
              {/* maroon bar */}
              <Bar
                dataKey='starting_amount'
                stackId='assets'
                barSize={70}
                yAxisId='left'
                fill={projectionsColors.initialDeposit}
              />
              <Bar
                //light pink
                barSize={70}
                dataKey='gainOnStartingAmount'
                stackId='assets'
                fill={projectionsColors.gain}
                yAxisId='left'
              ></Bar>
              {/* purple */}
              <Bar
                barSize={70}
                dataKey='gainOnReInvestedAmount'
                stackId='assets'
                fill={projectionsColors.gainOnReInvestment}
                yAxisId='left'
              >
                {data.length <= 20 ? (
                  <LabelList content={<CustomizedBarTopLabel />} position='top' />
                ) : null}
              </Bar>
              {/* red */}
              <Bar
                barSize={70}
                dataKey='lossByBorrowwedAmount'
                stackId='assets'
                fill={projectionsColors.borrow}
                yAxisId='left'
              ></Bar>
            </BarChart>
          ) : (
            <LineChart
              width={800}
              height={250}
              data={data}
              margin={{top: 20, right: 30, left: 0, bottom: 5}}
              style={{backgroundColor: 'white'}}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='year' />
              <YAxis /> {/* Hiding YAxis as we're displaying data on the lines */}
              {/* <Tooltip /> */}
              <Tooltip contentStyle={{backgroundColor: 'white'}} content={<SBLOCTooltip />} />
              {/* <Legend /> */}
              {/* Line for future value */}
              <Line
                type='monotone'
                dataKey='starting_amount'
                // fill={projectionsColors.initialDeposit}
                stroke={projectionsColors.initialDeposit}
                strokeWidth={6}
                // dot={<CustomizedDot />}
              />
              <Line
                type='monotone'
                dataKey='gainOnReInvestEveryYearForLineGraph'
                stroke={projectionsColors.gainOnReInvestment}
                strokeWidth={2}
                // dot={<CustomizedDot />}
              />
              <Line
                type='monotone'
                dataKey='lossByBorrowedAmountForLineChart'
                stroke={projectionsColors.borrow}
                // dot={<CustomizedDot />}
              />
              <Line
                type='monotone'
                dataKey='futureValue'
                stroke={projectionsColors.gain}
                strokeWidth={6} // Adjust line thickness here
                // dot={<CustomizedDot />}
              >
                <LabelList content={<CustomizedBarTopLabel />} position='top' />
              </Line>
            </LineChart>
          )}
        </ResponsiveContainer>
        <div className='form-check form-switch mt-3 d-flex justify-content-end align-items-center'>
          <input
            className='form-check-input'
            type='checkbox'
            role='switch'
            id='flexSwitchCheckDefault'
            onClick={() => setIsLineGraph(!isLineGraph)}
          />
          <label
            className='sliding-toggle-label form-check-label ms-2'
            htmlFor='flexSwitchCheckDefault'
          >
            Line Graph ?
          </label>
        </div>
      </div>
    </>
  )
}

export default memo(SBLOCChart)
