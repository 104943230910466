import axios from 'axios'
const ADD_TO_WAITLIST = 'waitlist/add-user'
const FETCH_WAITLIST_COUNT = 'waitlist/count'
const FETCH_PROGRESSIOSN = 'waitlist/progression'
const SET_PROGRESSION = 'waitlist/set-progression'
const FIND_USER = 'waitlist/findUser'

// fetching waitlist
export function addToWaitlist(email: string) {
  return axios.post(ADD_TO_WAITLIST, {
    email,
  })
}

export function getWaitListCount() {
  return axios.get(FETCH_WAITLIST_COUNT)
}

export function getProgressionCount(slug: string) {
  // Construct the URL with the slug as a query parameter
  const url = `${FETCH_PROGRESSIOSN}?slug=${encodeURIComponent(slug)}`

  // Return the axios GET request
  return axios.get(url)
}

export function setProgressionCount(slug: string, progression: number) {
  // Construct the URL with the slug as a query parameter
  // Return the axios GET request
  const url = `${SET_PROGRESSION}?slug=${encodeURIComponent(slug)}`
  return axios.put(url, {progression})
}

export function getUserByEmail(slug: string) {
  // Construct the URL with the slug as a query parameter
  const url = `${FIND_USER}?slug=${encodeURIComponent(slug)}`

  // Return the axios GET request
  return axios.get(url)
}
