import ProjectionsChart from './components/projections-chart/ProjectionsChart'
import FundsComparisonChart from './components/funds-comparison-chart/FundsComparisonChart'

const StackBarChart: React.FC<any> = (props) => {
  const {data} = props

  return (
    <>{!data[0]?.fee ? <ProjectionsChart data={data} /> : <FundsComparisonChart data={data} />}</>
  )
}
export default StackBarChart
