import Chats from './components/Chats'
import FeedBackModal from '../../../modals/FeedBackModal'
import {useChatBotState} from './components/ChatbotUtils'

const Chatbot: React.FC = () => {
  const {
    messages,
    isLoading,
    showSuggestedBubble,
    suggestedMessages,
    userResponse,
    inputRef,
    textAreaRef,
    handleSubmit,
    handleKeyDown,
    handleInputChange,
    handleSuggestedMessages,
    showFeedbackModal,
    setShowFeedbackModal,
  } = useChatBotState()

  return (
    <div className='chat-container d-flex flex-column justify-content-between mt-5 m-auto w-md-75 position-relative'>
      <Chats messages={messages} isLoading={isLoading} />

      {/* feedback modal */}
      <FeedBackModal
        show={showFeedbackModal}
        onHide={() => setShowFeedbackModal(false)}
      ></FeedBackModal>

      <div className='position-sticky bottom-0' style={{background: '#f9f9f9'}}>
        {/* suggestion bubble */}
        {showSuggestedBubble ? (
          <>
            <div className='suggestion-bubble-container row m-0 mb-4 gap-2 d-lg-flex justify-content-around'>
              {suggestedMessages.map((msg) => (
                <button
                  className='suggestion-bubble-button col-md-6 p-0'
                  disabled={isLoading}
                  onClick={(e) => handleSuggestedMessages(e, msg.message)}
                >
                  <p className='suggestion-bubble-text text-start mb-0 px-5 py-3'>{msg.message}</p>
                </button>
              ))}
            </div>
          </>
        ) : (
          ''
        )}

        {/* chat search bar */}
        <form onSubmit={(e) => handleSubmit(e)} className='chat-input-container d-flex px-3'>
          <textarea
            ref={(element: any) => {
              // set both refs to the same element
              inputRef.current = element
              textAreaRef.current = element
            }}
            disabled={isLoading}
            onChange={(e) => handleInputChange(e)}
            value={userResponse}
            placeholder='Message Chloee...'
            className='chat-input'
            onKeyDown={(e: any) => handleKeyDown(e)}
          />
          <button disabled={isLoading} className='chat-button'>
            {isLoading ? (
              <span className='spinner-border spinner-border-sm' style={{color: '#fff'}}></span>
            ) : (
              <i className='fa fa-arrow-up' style={{color: '#fff'}} />
            )}
          </button>
        </form>
        <div className='justify-content-center text-center'>
          <p className='font-size-small'>
            Disclaimer: AI can make mistakes, and cannot give investment advice. Please confirm
            numbers, math, and any recommendations before giving to client.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Chatbot
