import React, {useEffect, useRef} from 'react'
import {IMessagesInfo} from '../core/_model'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import Threads from './Threads'

interface Props {
  messages: IMessagesInfo[]
  isLoading: boolean
}

const Chats: React.FC<Props> = (props) => {
  const {messages, isLoading} = props
  const dummyRef = useRef<HTMLDivElement>(null)
  const bodyRef = useRef<HTMLDivElement>(null)
  // enable auto scroll after each message
  useEffect(() => {
    if (dummyRef.current) {
      dummyRef.current.scrollIntoView({behavior: 'smooth'})
    }
  }, [messages])

  return (
    <div ref={bodyRef}>
      {messages.map((chat, index) => (
        <>
          <div key={index}>
            {chat.sender === 'user' ? (
              <Threads
                id={`message${index}`}
                message={chat.message}
                userRole={chat.sender}
                chartData={chat.chartData}
                prevChartData={chat.prevChartData}
                imageSrc={toAbsoluteUrl('/media/avatars/user.svg')}
              />
            ) : (
              <Threads
                id={`message${index}`}
                message={chat.message}
                userRole={chat.sender}
                chartData={chat.chartData}
                prevChartData={chat.prevChartData}
                imageSrc={toAbsoluteUrl('/media/avatars/chloee-avatar.svg')}
                messageType={chat.messageType}
              />
            )}
          </div>
          <div ref={dummyRef} className='dummy-div'></div>
        </>
      ))}
      {isLoading ? (
        <div className='d-flex p-md-2 mb-7 align-items-center'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/avatars/blank.png')}
            className='avatars me-2'
          />
          <i
            className='fa-solid fa-ellipsis fa-beat-fade'
            style={{color: '#0C1721', fontSize: '2rem'}}
          ></i>
        </div>
      ) : null}
      <div ref={dummyRef} className='dummy-div'></div>
    </div>
  )
}

export default Chats
