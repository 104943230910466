export const roundToLowest = (value: number) => {
  return Math.floor(value)
}

export const toAbbreviatedNumber = (value: number) => {
  if (value < 1e3) return +value.toString()
  if (value >= 1e3 && value < 1e6) return +(value / 1e3).toFixed(1) + 'K'
  if (value >= 1e6 && value < 1e9) return +(value / 1e6).toFixed(1) + 'M'
  if (value >= 1e9 && value < 1e12) return +(value / 1e9).toFixed(1) + 'B'
  if (value >= 1e12) return +(value / 1e12).toFixed(1) + 'T'
}

export const toUSD = (value: number) => {
  const formattedValue = Number(roundToLowest(value)).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  return formattedValue
}

export const toUSDWithoutRoundOff = (value: number) => {
  const formattedValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  return formattedValue.format(value)
}

// stack bar colors
export const stackBarColors = {
  loss: '#ff318c',
  gain: '#feb7ff',
  contributions: '#d80ed8',
  startingAmount: '#7c1e72',
  white: '#ffffff',
  expected: '#C0C0C0',
  gainOnReInvestment: '#db69d7',
  gray: '#181c32',
  black: '#000',
  fundsComparisonGain: '#92EB9A',
  fundsComparisonStartingAmount: '#F39EB8',
}

export const projectionsColors = {
  loss: '#ff318c',
  gain: '#feb7ff',
  contributions: '#d80ed8',
  initialDeposit: '#7c1e72',
  white: '#ffffff',
  borrow: '#f0568d',
  gainOnReInvestment: '#db69d7',
}

export interface SBLOCDataModel {
  starting_amount: number
  borrowed_amount: number
  year: number
  gainOnStartingAmount: number
  gainOnReInvestedAmount: number
  lossByBorrowwedAmount: number
  topLabelValue: number
  spread_this_year: number
  spread_with_reInvest_this_year: number
  return_this_year: number
  interest_this_period: number
  gain_this_period: number
  reInvest_gain_this_period: number
  total_value: number
  spread_total: number
  spread_with_reInvest_total: number
  total_interest: number
  total_reInvested_gains: number
  total_gains: number
  return_total: number
  approx_average_return_per_year: number
  futureValue: number
  gainOnReInvestEveryYearForLineGraph: number
  lossByBorrowedAmountForLineChart: number
}
export interface SBLOCChartProps {
  data: SBLOCDataModel[] // Define the type for the data prop
}
