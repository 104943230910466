import React from 'react'
import {TooltipProps} from 'recharts'
import {toUSD} from './charts.helper'
const SBLOCTooltip: React.FC<TooltipProps<number, string>> = ({active, payload}) => {
  if (!active || !payload || payload.length === 0 || payload[0]?.payload?.label === 'Custom') {
    return null
  }

  const data = payload[0].payload
  return (
    <div className='custom-tooltip'>
      {/* <p className='paragraph-small label-main'>{data.year}</p> */}

      {/* this year */}
      <span className='label-bold mt-4'>{data.year}</span>

      <p className='paragraph-x-small mb-0 mt-4'>
        Spread : <span className=''>{toUSD(data.spread_this_year)}</span>
      </p>

      <p className='paragraph-x-small mb-0'>
        Spread (with re-invest):{' '}
        <span className=''>{toUSD(data.spread_with_reInvest_this_year)}</span>
      </p>
      <p className='paragraph-x-small mb-5'>
        Return : <span className=''>{parseFloat(data.return_this_year).toFixed(2)}%</span>
      </p>
      <p className='paragraph-x-small mb-0 label-borrow'>
        -Interest: <span className=''>{toUSD(data.interest_this_period)}</span>
      </p>
      <p className='paragraph-x-small mb-0 label-re-invest'>
        + Gain (re-investing):{' '}
        <span className='label-bold'>{toUSD(data.reInvest_gain_this_period)}</span>
      </p>
      <p className='paragraph-x-small mb-10 label-gain'>
        + Gain (on starting asset):{' '}
        <span className='label-bold'>{toUSD(data.gain_this_period)}</span>
      </p>

      {/* totals to date */}

      <span className='label-bold'>Totals To {data.year + 1}</span>
      <p className='paragraph-x-small mb-0 mt-4'>
        Total Value: <span className=''>{toUSD(data.total_value)}</span>
      </p>

      <p className='paragraph-x-small mb-0'>
        Spread: <span className=''>{toUSD(data.spread_total)}</span>
      </p>
      <p className='paragraph-x-small mb-0'>
        Spread with re-investment:{' '}
        <span className=''>{toUSD(data.spread_with_reInvest_total)}</span>
      </p>

      <p className='paragraph-x-small mb-0'>
        Total ROI: <span className=''>{parseFloat(data.return_total).toFixed(2)}%</span>
      </p>
      <p className='paragraph-x-small mb-3 '>
        Approx average yearly return:{' '}
        <span className=''>{parseFloat(data.approx_average_return_per_year).toFixed(2)}%</span>
      </p>
      <p className='paragraph-x-small mb-0 label-borrow'>
        -Total Interest: <span className='label-bold'>{toUSD(data.total_interest)}</span>
      </p>
      <p className='paragraph-x-small mb-0 label-re-invest'>
        +Total Gains (re-investing):{' '}
        <span className='label-bold'>{toUSD(data.total_reInvested_gains)}</span>
      </p>
      <p className='paragraph-x-small mb-5 label-gain'>
        +Total Gains (on starting asset):{' '}
        <span className='label-bold'>{toUSD(data.total_gains)}</span>
      </p>
      <p className='paragraph-x-small mb-0 label-borrow'>
        Amount Borrowed: <span className='label-bold'>{toUSD(data.borrowed_amount)}</span>
      </p>
      <p className='paragraph-x-small mb-0 label-initial-deposit'>
        Starting Amount: <span className='label-bold'>{toUSD(data.starting_amount)}</span>
      </p>
    </div>
  )
}

export default SBLOCTooltip
