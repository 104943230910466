import React from 'react'
import {TooltipProps} from 'recharts'
import {toUSD} from './charts.helper'

const ProjectionChartTooltip: React.FC<TooltipProps<number, string>> = ({active, payload}) => {
  if (!active || !payload || payload.length === 0 || payload[0]?.payload?.label === 'Custom') {
    return null
  }

  // chart data
  const data = payload[0].payload

  const convertFrequencyToReadableFrequency = (freq: number) => {
    if (freq === 1) {
      return 'Yearly'
    } else if (freq === 2) {
      return 'Bi-monthly'
    } else if (freq === 3) {
      return 'Quarterly'
    } else if (freq === 6) {
      return 'Semi-annually'
    } else if (freq === 12) {
      return 'Monthly'
    }
  }

  const LossLabel = ({value}: {value: number}) => {
    return (
      <p className='paragraph-x-small mb-0 label-loss'>
        Loss: <span className='label-bold'>{toUSD(Math.abs(value))}</span>
      </p>
    )
  }

  const GainLabel = ({value}: {value: number}) => {
    return (
      <p className='paragraph-x-small mb-0 label-gain'>
        Gain: <span className='label-bold'>{toUSD(value)}</span>
      </p>
    )
  }

  const ContributionsLabel = ({value}: {value: number}) => {
    return (
      <p className='paragraph-x-small mb-0 label-contributions'>
        Total contributions: <span className='label-bold'>{toUSD(value)}</span>
      </p>
    )
  }
  const InitialContributionsLabel = ({freq, value}: {freq: number; value: number}) => {
    return (
      <p className='paragraph-x-small mb-0 label-contributions'>
        {convertFrequencyToReadableFrequency(freq)} contributions:{' '}
        <span className='label-bold'>{toUSD(value)}</span>
      </p>
    )
  }

  return (
    <div className='custom-tooltip'>
  {data.is_chart_by_lump_sum_amount ? (
    <>
{!data.loss ? <GainLabel value={data?.gains} /> : <LossLabel value={data?.loss} />}
  {/* Starting amount */}
{data?.starting_amount !== 0 && (
  <p className='paragraph-x-small mb-0 label-initial-deposit'>
    Starting amount:  <span className='label-bold'>{toUSD(data?.starting_amount)}</span>
  </p>
)}
  <p className='paragraph-x-small mb-0 label-initial-deposit'>
    Description: <span> {data.description} </span>
  </p>
</>
) :(
  <>
    <p className='paragraph-small label-main'>{data.investment_name}</p>

{/* Gains */}
{!data.is_loss ? <GainLabel value={data?.gains} /> : <LossLabel value={data?.gains} />}

{/* Initial contributions and frequency */}
{data?.contributions !== 0 && (
  <p className='paragraph-x-small mb-0 label-initial-deposit'>
    {
      <InitialContributionsLabel
        freq={data?.contributions_frequency}
        value={data?.contributions / data?.contributions_frequency / data?.years_to_grow}
      />
    }
  </p>
)}

{/* Total contributions */}
{data?.contributions !== 0 && (
  <p className='paragraph-x-small mb-0 label-initial-deposit'>
    {<ContributionsLabel value={data?.contributions} />}
  </p>
)}

{/* Starting amount */}
{data?.starting_amount !== 0 && (
  <p className='paragraph-x-small mb-0 label-initial-deposit'>
    Starting amount: <span className='label-bold'>{toUSD(data?.starting_amount)}</span>
  </p>
)}
  </>
)
}


    
    </div>
  )
}

export default ProjectionChartTooltip
